import React from "react";
import ImageWrapper from "../Image/ImageWrapper";
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from "../../store/actions";

const BLOCK_CLASS = "wishlist";

const WishlistCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserLoggedIn = useSelector((state) => state.isUserLoggedIn);

  const onCardClick = () => {
    navigate(`/product/${props.data?.variant_properties?.art?.replaceAll(
    " ",
    "-"
  )}-${props.data?.product_name?.replaceAll(" ", "-")?.replaceAll("/", "-")}-${
    props.data.variant_sku
  }`);    
  }

  const onAddToCartClick = () => {
    let variantSku = props?.data?.variant_sku;

    let cart_item = {
      isUserLoggedIn: isUserLoggedIn,
      variant_id: variantSku,
      name: props.data?.product_name,
      quantity: 1,
      size: props.data?.variant_properties?.size,
      price: props.data?.variant_price,
      thumbnail: props.data?.thumbnail,
    };
    dispatch(addToCart({ data: cart_item }));
  };

  const renderPrice = () => {

    return (
      <>
        {props.data?.discount_amount > 0 ? (
          <p className={`${BLOCK_CLASS}__price price`}>
            <span>
              <b>Rs.</b> {props.data.discounted_price}
            </span>
            <span>
              <b>Rs.</b> {props.data.variant_price}
            </span>
          </p>
        ) : (
          <p className={`${BLOCK_CLASS}__price`}>
            <span>
              <b>Rs.</b> {props.data.variant_price}
            </span>
          </p>
        )}
      </>
    );
  }

  return (
    <div className={`${BLOCK_CLASS}__card`} onClick={onCardClick}>
      <div className={`${BLOCK_CLASS}__info-wrapper`}>
        <p className={`${BLOCK_CLASS}__name`}>
          {props.data.name || props.data.product_name}
        </p>
      </div>

      <ImageWrapper
        externalUrl={props.data.thumbnail}
        name="women-kurti"
        alt=""
        classes={`${BLOCK_CLASS}__image`}
      />

      {props?.data?.tags?.map((tag) => {
        return <p className={`${BLOCK_CLASS}__badge`}>{tag.name}</p>;
      })}

      {/* <p className={`${BLOCK_CLASS}__price`}>
        <span>Rs.</span> {props.data.variant_price || '--'}
      </p> */}

      {
        renderPrice()
      }

      {props.data.quantity < 5 ? (
        <p className={`${BLOCK_CLASS}__label`}>Only few Left</p>
      ) : (
        ""
      )}

      <Button className={`${BLOCK_CLASS}__buy-now-cta`} onClick={onAddToCartClick}>
        Add to cart
      </Button>
    </div>
  );
};

export default WishlistCard;
