import { Button, Popconfirm } from "antd";
import React, { useEffect } from "react";
import SideNavigation from "../../components/SideNavigation/SideNavigation";
import ImageWrapper from "../../components/Image/ImageWrapper";
import { useDispatch, useSelector } from 'react-redux';
import { cancelOrder, getOrders, toggleMobileDrawerMenu } from '../../store/actions';
import { Link } from "react-router-dom";

const ORDER_CLASS = "order";
const BLOCK_CLASS = "account";

const items = [
  {
    id: "1",
    name: "Mustard Cotton Silk Printed Sari",
    size: "10",
    status: "In Transit",
  },
  {
    id: "2",
    name: "Mustard Cotton Silk Printed Sari",
    size: "10",
    status: "Delivered on 17th May",
  },
];
const Orders = () => {
  const dispatch = useDispatch();
  const isMobileMenuOpen = useSelector((state) => state.isMobileMenuOpen);
  const userDetails = useSelector((state) => state.userDetails);
  const ordersList = useSelector((state) => state.orders);

  useEffect(()=> {
    onLoad();
  }, []);
  
  const onLoad = () => {
    window.scrollTo(0, 0);
    if (isMobileMenuOpen) {
      dispatch(toggleMobileDrawerMenu());
    }

    dispatch(getOrders(1));
  }

  const onCancelOrder = (id) => {
    dispatch(cancelOrder(id));
  }

  const cancel = (e) => {};

  return (
    <div className="account-main">
      <div className={`${BLOCK_CLASS}`}>
        <div className={`${BLOCK_CLASS}__title`}>
          <p>
            Welcome!{" "}
            {userDetails?.first_name ? userDetails?.first_name : "User"}
          </p>
        </div>
        <div className="profile-page">
          <SideNavigation />
          <div className={`${ORDER_CLASS}`}>
            <ul className={`${ORDER_CLASS}__order-inner`}>
              {ordersList?.orders?.map((item) => (
                <div className={`${ORDER_CLASS}__item`}>
                  <p className={`${ORDER_CLASS}__info-text`}>
                    <span>
                      <b>Order no.</b> : {item.order_number}
                    </span>
                    {item.transaction_id !== undefined &&
                    item.transaction_id !== null ? (
                      <span>
                        <b>Transaction ID</b> : {item.transaction_id}
                      </span>
                    ) : null}
                    <span>
                      <b>Order Date</b> : {item.placed_at}
                    </span>

                    <span>
                      <b>Total Amount</b> : Rs.{item.total}
                    </span>
                    <span>
                      <b>Order Status</b> : {item.status}
                    </span>
                  </p>
  
                  <span className="border-separator"></span>
                  {item.order_items.map((product, i) => {
                    return (
                      <li
                        key={product.sku + i}
                        className={`${ORDER_CLASS}__order-list`}
                      >
                        <div className={`${ORDER_CLASS}__order-img`}>
                          <ImageWrapper
                            alt="order"
                            name="order-img"
                            externalUrl={product.thumbnail_url}
                          />
                        </div>
                        <div className={`${ORDER_CLASS}__order-content`}>
                          <p>{product.name}</p>
                          <p>Quantity: {product.units}</p>
                          <div className="order-info">
                            {/* <p>
                                Status - <span>{item.status}</span>
                              </p> */}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                  <div className="order-btn">
                    {item.cancellable ? (
                      <Popconfirm
                      title="Cancle order"
                      description="Are you sure you want to cancel?"
                      onConfirm={() => {
                        onCancelOrder(item.id);
                      }}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        danger
                        className="cancel-btn btn"
                        // onClick={() => {
                        //   onCancelOrder(item.id);
                        // }}
                      >
                        Cancel Order
                      </Button>
                    </Popconfirm>
                    ) : null}
                    {/* {item.exchangeable ? (
                      <Button className="cancel-btn btn">Exchange</Button>
                    ) : null} */}
                    <Link
                      className={`${ORDER_CLASS}__track-cta`}
                      to={`https://nilam.shiprocket.co/tracking/order/${item.order_number}`}
                      target="_blank"
                    >
                      Track order
                    </Link>
                  </div>
                </div>
              ))}
            </ul>

            {ordersList?.orders == undefined ||
            ordersList?.orders?.length == 0 ? (
              <>
                <ImageWrapper
                  name="no-orders"
                  alt={"no-orders"}
                  classes={`${ORDER_CLASS}__no-orders`}
                ></ImageWrapper>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
