import { useDispatch, useSelector, connect } from "react-redux";
import { Button, Drawer, Select } from "antd";
import {
  hideDrawer,
  togglePlaceOrderModal,
  updateQuantityOfCartItem,
  removeCartItem,
  addToCart,
} from "../../store/actions";
import { DRAWER_TYPES } from "../../utils/enumData";
import ImageWrapper from "../Image/ImageWrapper";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function Cart(props) {
  const BLOCK_CLASS = "cart";
  const dispatch = useDispatch();
  const isCartVisible = useSelector((state) => state.isCartVisible);
  const [isAnyItemOutOfStock, setItemOutOfStock] = useState(false);
  const [stockLabelsPerItem, setStockLabelsPerItem] = useState([]);

  useEffect(() => {
    let itemsInfoLabel = [];
    if (
      props?.cartDetails?.items !== undefined &&
      props?.cartDetails?.items !== null &&
      props?.cartDetails?.items.length > 0
    ) {
      itemsInfoLabel = props?.cartDetails?.items.map((item) => {
        if (item.variant_quantity == 0) {
          setItemOutOfStock(true);
          return "Product out of stock!";
        } else if (item.quantity > item.variant_quantity) {
          setItemOutOfStock(true);
          return `Only ${item.variant_quantity} quantity available`;
        } else {
          return "";
        }
      });
      setStockLabelsPerItem(itemsInfoLabel);
    }
  }, [props.cartDetails]);

  const onClose = () => {
    dispatch(hideDrawer({ type: DRAWER_TYPES.cart }));
  };

  const placeOrder = () => {
    dispatch(hideDrawer({ type: DRAWER_TYPES.cart }));
    dispatch(togglePlaceOrderModal({ type: "placeorder" }));
  };

  const removeItemfromCart = (data) => {
    dispatch(
      removeCartItem({
        itemId: data.cart_item_id,
        isUserLoggedIn: props.isUserLoggedIn,
      })
    );
  };

  const getTotalPrice = (value) => {
    let totalAmount = 0;
    props?.cartDetails?.items?.forEach((product) => {
      totalAmount = totalAmount + product.total_price * product.quantity;
    });

    return totalAmount;
  };

  const addItemToCart = (e, quantity, data) => {
    e.preventDefault();
    if (quantity > 0 && quantity <= props?.variantDetails?.quantity) {
      let updatedItem = {
        ...data,
        quantity: quantity,
        isUserLoggedIn: props.isUserLoggedIn,
      };
      dispatch(addToCart({ data: updatedItem }));
    }
  };

  const isAvailableForCheckout = () => {
    return props?.cartDetails?.items?.length > 0 && !isAnyItemOutOfStock
      ? false
      : true;
  };

  const getProductUrl = (data) => {
    let artName = data?.variant_properties?.art?.replaceAll(" ", "-");
    let productName = data?.product_name
      ? data?.product_name?.replaceAll(" ", "-")?.replaceAll("/", "-")
      : data?.name?.replaceAll(" ", "-")?.replaceAll("/", "-");
    let sku = data?.variant_sku ? data.variant_sku : data.variant_id;
    
    return `/product/${artName}-${productName}-${sku}`;
  }

  const renderPrice = (data) => {
    if (data == undefined || data == null) return

    return (
      <>
        {data.discount > 0 ? (
          <h4
            className={`${BLOCK_CLASS}__item-label ${BLOCK_CLASS}__item-label--price price`}
          >
            {
              props.isUserLoggedIn ? (<span>Rs. {data.total_price}</span>) : (<span>Rs. {data.total_price * data.quantity}</span>)
            }
            <span>Rs. {data.unit_price * data.quantity}</span>
          </h4>
        ) : (
          <h4
            className={`${BLOCK_CLASS}__item-label ${BLOCK_CLASS}__item-label--price`}
          >
            {
              props.isUserLoggedIn ? (<span>Rs.{data.unit_price}</span>) : (<span>Rs.{data.unit_price * data.quantity}</span>)
            }
          </h4>
        )}
      </>
    );
  }

  const getTotalDiscountOnAllProducts = () => {
    let totalDiscount = 0;

    props.cartDetails?.items.forEach((item)=> {
      if (props.isUserLoggedIn) {
        totalDiscount += item.discount;
      } else {
        totalDiscount += item.discount * item.quantity;
      }
    });

    return totalDiscount - totalDiscount == 0 ? totalDiscount : 0;
  }

  return (
    <Drawer
      title="CART"
      onClose={onClose}
      open={isCartVisible}
      className={`${BLOCK_CLASS} ${isCartVisible ? "" : "no-pad"}`}
    >
      <div className={`${BLOCK_CLASS}__items-wrapper`}>
        {props?.cartDetails?.items?.length > 0 ? (
          props.cartDetails?.items.map((data, i) => {
            return (
              <div className={`${BLOCK_CLASS}__item`} key={data.cart_item_id}>
                <a href={getProductUrl(data)}>
                  <ImageWrapper
                    externalUrl={data.thumbnail}
                    name="women"
                    alt="women"
                    classes={`${BLOCK_CLASS}__item-image`}
                  />
                </a>
                <div className={`${BLOCK_CLASS}__item-details`}>
                  <a href={getProductUrl(data)}>
                    <h4
                      className={`${BLOCK_CLASS}__item-label ${BLOCK_CLASS}__item-label--name`}
                    >
                      {data.product_name}
                    </h4>
                  </a>
                  <a
                    className={`${BLOCK_CLASS}__middle-block`}
                    href={getProductUrl(data)}
                  >
                    <div className={`${BLOCK_CLASS}__item-cta-wrapper`}>
                      {data?.variant_properties?.size ? (
                        <h4
                          className={`${BLOCK_CLASS}__item-label ${BLOCK_CLASS}__item-label--size`}
                        >
                          Size:{" "}
                          <span className={`${BLOCK_CLASS}__item-label--value`}>
                            {data?.variant_properties?.size}
                          </span>
                        </h4>
                      ) : (
                        ""
                      )}
                      <div className={`${BLOCK_CLASS}__quantity-wrapper`}>
                        <Button
                          onClick={(e) => {
                            addItemToCart(e, data.quantity - 1, data);
                          }}
                        >
                          -
                        </Button>{" "}
                        {data.quantity}{" "}
                        <Button
                          onClick={(e) => {
                            addItemToCart(e, data.quantity + 1, data);
                          }}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                  </a>
                  {
                    <>
                      {stockLabelsPerItem[i] !== "" ? (
                        <div className={`${BLOCK_CLASS}__price-block`}>
                          <h4
                            className={`${BLOCK_CLASS}__item-label ${BLOCK_CLASS}__item-label--price`}
                          >
                            Rs. {data.total_price}
                          </h4>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  }
                  <div className={`${BLOCK_CLASS}__last-block`}>
                    {stockLabelsPerItem[i] !== "" ? (
                      <span
                        className={`${BLOCK_CLASS}__item-label ${BLOCK_CLASS}__item-label--stock-info`}
                      >
                        {stockLabelsPerItem[i]}
                      </span>
                    ) : (
                      <>{renderPrice(data)}</>
                    )}

                    <ImageWrapper
                      name="delete"
                      alt="remove item"
                      classes={`${BLOCK_CLASS}__remove-icon`}
                      onclick={() => {
                        removeItemfromCart(data);
                      }}
                    ></ImageWrapper>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <>
            <ImageWrapper
              name="empty-cart"
              alt="empty cart"
              classes={`${BLOCK_CLASS}__empty-cart`}
            ></ImageWrapper>
            <p className={`${BLOCK_CLASS}__empty-cart-text`}>
              An empty cart isn’t your vibe
            </p>
          </>
        )}
      </div>
      <div className={`${BLOCK_CLASS}__summary`}>
        <p className={`${BLOCK_CLASS}__amount-label-shipping`}>
          <span>
            Shipping Charges <small></small>
          </span>
          {props?.cartDetails?.items?.length > 0 ? (
            <span>
              <span className={`slashed-price`}>Rs. 209</span> <b>Free</b>
            </span>
          ) : (
            <span>Rs.0</span>
          )}
        </p>
        
        {props?.cartDetails?.items?.length > 0 ? (
          <p className={`${BLOCK_CLASS}__amount-label`}>
            <span>Discount On MRP</span>
            <span className="green-f">
              Rs. {getTotalDiscountOnAllProducts()}
            </span>
          </p>
        ) : (
          ""
        )}

        <p className={`${BLOCK_CLASS}__amount-label`}>
          <span>
            Sub Total <small>(incl taxes)</small>
          </span>
          <span>
            Rs.
            {props.isUserLoggedIn && props.cartDetails?.total
              ? props.cartDetails.total
              : getTotalPrice()}
          </span>
        </p>

        <div className={`${BLOCK_CLASS}__btn-wrapper`}>
          <Button
            className={`${BLOCK_CLASS}__primary-cta`}
            onClick={placeOrder}
            disabled={isAvailableForCheckout()}
          >
            Place order
          </Button>
          <Button className={`${BLOCK_CLASS}__secondary-cta`} onClick={onClose}>
            <Link to="/collection/Clothing">Continue shopping</Link>
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

function mapStateToProps(state, ownProps) {
  const { cartItems, cartDetails, isUserLoggedIn, product } = state;
  return {
    cartItems: cartItems,
    cartDetails,
    isUserLoggedIn,
    variantDetails: product ? product.variantDetails : null,
  };
}

export default connect(mapStateToProps)(Cart);
