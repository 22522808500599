import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import ImageWrapper from "../../components/Image/ImageWrapper";
import Story from "../../components/Story/Story";
import Freshness from "./Freshness";
import Categories from "./Categories";
import Reminder from "./Reminder";
import Features from "./Features";
import Testimonials from "./Testimonials";
import Newsletter from "./Newsletter";
import Givetime from "./Givetime";
import Banner from "./Banner";

const Home = (props) => {
  const videoRef = useRef();
  const [firstBannerImgData, setFirstBannerData] = useState([
    {
      mobile_name: 'live-sale-mobile',
      desktop_name: 'live-sale-web',
      mobile_extenstion: '.png',
      desktop_extension: '.png',
      redirect_link: '',
    },
    {
      mobile_name: 'kimono_banner_mobile',
      desktop_name: 'kimono_banner_desktop',
      mobile_extenstion: '.png',
      desktop_extension: '.webp',
      redirect_link: '/products/Clothing/Dresses/4379fbae-3ca2-4a3b-9e15-eb8c46106b7f',
    },
    {
      mobile_name: 'dress-banner-mobile',
      desktop_name: 'dress-banner-desktop',
      mobile_extenstion: '.png',
      desktop_extension: '.webp',
      redirect_link: '/products/Clothing/Dresses/4379fbae-3ca2-4a3b-9e15-eb8c46106b7f',
    }
  ]);
  const [secondBannerImgData, setSecondBannerData] = useState([
    {
      mobile_name: 'bags-banner-mobile',
      desktop_name: 'bags-banner-desktop',
      mobile_extenstion: '.png',
      desktop_extension: '.webp',
      redirect_link: '/products/Bags/5b8b2369-bffe-46b1-beab-6ad4cd779a9f',
    }
  ]);

  useEffect(()=> {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (props.catalogue !== undefined && props.catalogue !== null) {
      let firstBannerData = [
        {
          mobile_name: 'live-sale-mobile',
          desktop_name: 'live-sale-web',
          mobile_extenstion: '.png',
          desktop_extension: '.png',
          redirect_link: '/collection/Clothing',
        },
        {
          mobile_name: "kimono_banner_mobile",
          desktop_name: "kimono_banner_desktop",
          mobile_extenstion: ".png",
          desktop_extension: ".webp",
          redirect_link:
            `/products/Clothing/Kimono/${props?.catalogue?.categories?.Clothing?.subcategories?.Kimono?.id}`,
        },
        {
          mobile_name: "dress-banner-mobile",
          desktop_name: "dress-banner-desktop",
          mobile_extenstion: ".png",
          desktop_extension: ".webp",
          redirect_link:
            `/products/Clothing/Dresses/${props?.catalogue?.categories?.Clothing?.subcategories?.Dresses?.id}`,
        },
      ];

      let secondBannerData = [
        {
          mobile_name: 'bags-banner-mobile',
          desktop_name: 'bags-banner-desktop',
          mobile_extenstion: '.png',
          desktop_extension: '.webp',
          redirect_link: `/products/Bags/${props?.catalogue?.categories?.Bags?.id}`,
        }
      ];

      setFirstBannerData(firstBannerData);
      setSecondBannerData(secondBannerData);
    }
  }, [props.catalogue]);

  return (
    <div className="home">
      <section className="hero">
        <video
          autoPlay
          muted={true}
          loop
          playsInline
          ref={videoRef}
          poster={`${process.env.PUBLIC_URL}../../../assets/images/poster.jpg`}
        >
          <source
            src={`${process.env.PUBLIC_URL}../../../assets/videos/cover.mp4`}
            type="video/mp4"
          />
        </video>

        <ImageWrapper
          name={`${
            window.innerWidth < 1024 ? "banner-btm-mobile" : "banner-btm-desk"
          }`}
          alt="Clothing-img4"
          classes={`bg-element`}
        />
      </section>
      <Freshness />
      <Banner placement={1} imgData={firstBannerImgData}/>
      <Categories />
      <Reminder />
      <Features />
      <Banner placement={2} imgData={secondBannerImgData}/>
      <section className="story-section">
        <Story />
      </section>
      <Testimonials />
      <Givetime />
      <Newsletter />
    </div>
  );
};

// export default Home;

function mapStateToProps(state, ownProps) {
  const { catalogue } = state;
  return { catalogue: catalogue };
}

export default connect(mapStateToProps)(Home);
