import ImageWrapper from "../../components/Image/ImageWrapper";

const REMINDER_CLASS = "reminder";

const Reminder = () => {
  return (
    <section className="home-reminder">
      <div className="container">
        <div className={`${REMINDER_CLASS}`}>
          <ImageWrapper
            name={`${
              window.innerWidth < 1024 ? "mobile-separator" : "separator-bg"
            }`}
            alt="separator"
            classes={`design-separator`}
          />
          <div className={`${REMINDER_CLASS}__reminder-content`}>
            <p>
              <b>Nilam India offers a blend of premium craftsmanship and excellence.</b>
              <br></br>
              <br></br>
              Slow Fashion Life | Hand Blocked Artistry <br></br> Masterfully
              Hand Screened
              {/* You have an amazing soul.
              <br /> Don't you dare doubt yourself; keep shining like you always
              do.<br></br> We are rooting for you to achieve everything you
              dream of.<br></br> Just give it time; it's all coming together. :) */}
            </p>
          </div>
          <ImageWrapper
            name={`${
              window.innerWidth < 1024 ? "mobile-separator" : "separator-bg"
            }`}
            alt="separator"
            classes={`design-separator`}
          />
        </div>
      </div>
    </section>
  );
};

export default Reminder;
