import { memo, useEffect, useState } from "react";
import ImageWrapper from "../../components/Image/ImageWrapper";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleMobileDrawerMenu } from "../../store/actions";

const Categories = (props) => {
  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    setCategoriesList(props.categories)
  }, [props.categories]);

  return (
    <section className="categories">
      <div className="container">
        <div className="category-main">
          <div className="category-title">
            <h3>Shop by</h3>
            <h4>Categories</h4>
          </div>
          <div className="category-card">
            {categoriesList?.map((item) => {
              let url = (item.name === "Clothing") ? `collection/${item.name}` : `/products/Bags/${item.id}`;
              return (
                <div className="category-item" key={item.id}>
                  <figure>
                    <Link to={url} state={item}>
                      {item?.images[0]?.url !== undefined ? (
                        <ImageWrapper
                          externalUrl={item?.images[0]?.url}
                          alt={item.name}
                        />
                      ) : (
                        <ImageWrapper
                          name={"Women"}
                          alt={item.name}
                          srcType="internal"
                        />
                      )}
                    </Link>
                    <Link to={url} state={item}>
                      <figcaption>{item.name}</figcaption>
                    </Link>
                    <Link to={url} state={item}>
                      <div className="category-item__card-cta">
                        <p>Explore more</p>
                      </div>
                    </Link>
                  </figure>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

function mapStateToProps(state, ownProps) {
  const { categories, isMobileMenuOpen } = state;
  return { categories: categories, isMobileMenuOpen };
}

export default connect(mapStateToProps)(Categories);
