// Doc: https://redux-saga.js.org/docs/introduction/GettingStarted

import { all, put, call, takeLatest } from "redux-saga/effects";
import {
  getCategories,
  getSubCategories,
  getProducts,
  sendOTP,
  verifyOTP,
  getProductDetails,
  addToCart,
  getCartDetails,
  createCart,
  checkout,
  getPaymentStatus,
  removeCartItem,
  getCatalogue,
  updateQuantityOfCartItem,
  getAddresses,
  createAddress,
  updateAddress,
  deleteAddress,
  getOrders,
  getUserDetails,
  updateUserDetails,
  getWishlist,
  addToWishlist,
  removeFromWishlist,
  getRecommendedProducts,
  cancelOrder,
  subscribeEmail,
  checkPincode
} from "./actions";
import {ApiService} from '../services/ApiService';
import { DRAWER_TYPES } from "../utils/enumData";

function* fetchCategories(action) {
  try {
    let category_data = yield call(ApiService.getCategories);
    yield put({ type: "GET_CATEGORIES_SUCCESS", data: category_data.data });
  } catch (e) {
    yield put({ type: "GET_CATEGORIES_FAILURE", message: e.message });
  }
}

function* fetchSubCategories(action) {
  try {
    let categoryList = action.payload;
    const data = yield call(ApiService.getSubCategories, {category_name: categoryList});
    yield put({ type: "GET_SUBCATEGORIES_SUCCESS", data: data.data });
  } catch (e) {
    yield put({ type: "GET_SUBCATEGORIES_FAILURE", message: e.message });
  }
}

function* fetchProducts(action) {
  try {
    let categoryIdsList = action.payload;

    const data = yield call(ApiService.getProducts, { category_ids: categoryIdsList });
    yield put({ type: "GET_PRODUCTS_SUCCESS", data: data.data });
  } catch (e) {
    yield put({ type: "GET_PRODUCTS_FAILURE", message: e.message });
  }
}

function* getOTP(action) {
  try {
    const data = yield call(ApiService.sendOTP, action.payload);
    yield put({ type: "SEND_OTP_SUCCESS",  data : data });
  } catch (e) {
    yield put({ type: "SEND_OTP_FAILURE", message: 'error handling' });
  }
}

function* verifyUserOTP(action) {
  try {
    const data = yield call(ApiService.verifyOTP, action.payload);
    yield put({ type: "SHOW_TOAST_MESSAGE", payload: { success: true, message: 'Login Success' } });
    yield put({ type: "VERIFY_OTP_SUCCESS",  data : data.data });
    yield put({ type: "GET_CART_DETAILS" });


  } catch (e) {
    yield put({ type: "SHOW_TOAST_MESSAGE", payload: { success: false, message: e?.response?.data?.error } });
    yield put({ type: "VERIFY_OTP_FAILURE", message: 'error handling' });
  }
}

function* fetchProductDetails(action) {
  try {
    const variantData = yield call(ApiService.getVariant, { sku: action.payload.sku });
    const productData = yield call(ApiService.getProductDetails, { id: variantData.product_id });
    yield put({ type: "GET_PRODUCT_DETAILS_SUCCESS",  data: { productDetails: productData, variantDetails: variantData } });
  } catch (e) {
    yield put({ type: "GET_PRODUCT_DETAILS_FAILURE", message: 'error handling' });
  }
}

function* addItemToCart(action) {
  try {
    let cartItem = action.payload.data;

    if (action.payload.data.isUserLoggedIn) {
      const response = yield call(ApiService.addToCart, { data: cartItem });
      yield put({ type: "GET_CART_DETAILS" });
    } else {
      let data = {
        cart_item_id: cartItem.variant_id,
        product_name: cartItem.name,
        ...cartItem
    }
      yield put({ type: "ADD_TO_LOCAL_CART",  data: data });
    }

    yield put({ type: "SHOW_DRAWER", payload: { type: DRAWER_TYPES.cart } });
  } catch (e) {
    console.log(e);
  }
}

function* updateQuantity(action) {
  try {
    if (action.payload.isUserLoggedIn) {
      yield put({ type: "ADD_TO_CART" }, { data: action.payload });
    } else {
      yield put({ type: "ADD_TO_LOCAL_CART" }, { data: action.payload });
    }
  } catch (e) {
    yield put({ type: "GET_PRODUCT_DETAILS_FAILURE", message: 'error handling' });
  }
}

function* getCart(action) {
  try {
    const response = yield call(ApiService.getCartDetails);

    if (response.id) {
      yield put({ type: "GET_CART_DETAILS_SUCCESS",  data: response });
    }
    
  } catch (e) {
    console.log(e);
  }
}

function* createNewCart(action) {
  try {
    const response = yield call(ApiService.createCart, { cart_items: action.payload });
    yield put({ type: "GET_CART_DETAILS_SUCCESS",  data: response });
  } catch (e) {
    console.log(e);
  }
}

function* checkoutCart(action) {
  try {
    const response = yield call(ApiService.checkoutCart, { cart_id: action.payload.cart_id, address_id: action.payload.address_id });
    yield put({ type: "CHECKOUT_SUCCESS",  data: response });
  } catch (e) {
    // yield put({ type: "CHECKOUT_FAILURE",  data: e });
    yield put({ type: "SHOW_TOAST_MESSAGE", payload: e });
  }
}

function* checkStatus(action) {
  try {
    const response = yield call(ApiService.checkPaymentStatus, { transaction_id: action.payload });
    yield put({ type: "PAYMENT_SUCCESS", data: response });
  } catch (e) {
    yield put({ type: "PAYMENT_FAILURE",  data: e });
  }
}

function* removeItemInCart(action) {
  try {
    if (action.payload.isUserLoggedIn) {
      const response = yield call(ApiService.removeItemFromCart, { cart_item_id: action.payload.itemId });
      yield put({ type: "REMOVE_ITEM_SUCCESS", data: action.payload.itemId });
      yield put({ type: "GET_CART_DETAILS" });

    } else {
      yield put({ type: "REMOVE_ITEM_SUCCESS", data: action.payload.itemId });
    }
  } catch (e) {
    yield put({ type: "REMOVE_ITEM_FAILURE",  data: e });
  }
}

function* getCatalogueData(action) {
  try {
    const response = yield call(ApiService.getCatalogue);
    yield put({ type: "GET_CATALOGUE_SUCCESS", data: response });
  } catch (e) {
    yield put({ type: "GET_CATALOGUE_FAILURE",  data: e });
  }
}

function* getAddress(action) {
  try {
    const response = yield call(ApiService.getAddresses);
    yield put({ type: "GET_ADDRESSES_SUCCESS", data: response });
  } catch (e) {
    yield put({ type: "GET_ADDRESSES_FAILURE",  data: e });
  }
}

function* createNewAddress(action) {
  try {
    const response = yield call(ApiService.createAddress, { address: action.payload });
    yield put({ type: "GET_ADDRESSES" });
    
  } catch (e) {
    yield put({ type: "CREATE_ADDRESS_FAILURE",  data: e });
  }
}

function* updateAddressData(action) {
  try {
    const response = yield call(ApiService.updateAddress, { address: action.payload });
    
    yield put({ type: "GET_ADDRESSES" });
  } catch (e) {
    yield put({ type: "UPDATE_ADDRESS_FAILURE",  data: e });
  }
}

function* deleteAddressData(action) {
  try {
    const response = yield call(ApiService.deleteAddress, { address_id: action.payload });
  
    yield put({ type: "GET_ADDRESSES" });
  } catch (e) {
    yield put({ type: "DELETE_ADDRESS_FAILURE",  data: e });
  }
}

function* getOrdersByPage(action) {
  try {
    const response = yield call(ApiService.getOrders, { page: action.payload });
    yield put({ type: "GET_ORDERS_SUCCESS", data: response });
  } catch (e) {
    yield put({ type: "GET_ORDERS_FAILURE",  data: e });
  }
}

function* getUserProfile(action) {
  try {
    const response = yield call(ApiService.getUserProfile);
    yield put({ type: "GET_USER_DETAILS_SUCCESS", data: response });
  } catch (e) {
    yield put({ type: "GET_USER_DETAILS_FAILURE",  data: e });
  }
}

function* updateUserProfile(action) {
  try {
    const response = yield call(ApiService.updateUserProfile, { id: action.payload.id, details: action.payload.details });
    yield put({ type: "SHOW_TOAST_MESSAGE", payload: response });
    yield put({ type: "GET_USER_DETAILS" });
  } catch (e) {
    yield put({ type: "SHOW_TOAST_MESSAGE", payload: e });
    yield put({ type: "GET_USER_DETAILS" });
  }
}

function* getWishlistItems(action) {
  try {
    const response = yield call(ApiService.getWishlist);
    yield put({ type: "GET_WISHLIST_SUCCESS", data: response });
  } catch (e) {
    yield put({ type: "GET_WISHLIST_FAILURE",  data: e });
  }
}

function* addItemToWishlist(action) {
  try {
    const response = yield call(ApiService.addToWishlist, { variant_sku: action.payload });
    yield put({ type: "GET_WISHLIST" });
  } catch (e) {
    yield put({ type: "ADD_TO_WISHLIST_FAILURE",  data: e });
  }
}

function* removeItemFromWishlist(action) {
  try {
    const response = yield call(ApiService.removeFromWishlist, { variant_sku: action.payload });
    yield put({ type: "GET_WISHLIST" });
  } catch (e) {
    yield put({ type: "REMOVE_FROM_WISHLIST_FAILURE",  data: e });
  }
}

function* getAllRecommendedProducts(action) {
  try {
    const response = yield call(ApiService.getRecommendedProducts, { page_no: action.payload });
    yield put({ type: "GET_RECOMMENDED_PRODUCTS_SUCCESS", data: response });
  } catch (e) {
    yield put({ type: "GET_RECOMMENDED_PRODUCTS_FAILURE",  data: e });
  }
}

function* cancelOrderById(action) {
  try {
    const response = yield call(ApiService.cancelOrder, { order_id: action.payload });
    yield put({ type: "GET_ORDERS", data: 1 });
  } catch (e) {
    const errorMessage = e;
    yield put({ type: "SHOW_TOAST_MESSAGE", payload: errorMessage });
  }
}

function* subscribeEmailForNewsletter(action) {
  try {
    const response = yield call(ApiService.optInToNewsletter, action.payload);
    yield put({ type: "SUBSCRIBE_EMAIL_SUCCESS" });
  } catch (e) {
    yield put({ type: "SHOW_TOAST_MESSAGE", payload: e });
  }
}

function* checkPincodeForAvailability(action) {
  try {
    const response = yield call(ApiService.checkPincode, action.payload);
  
    yield put({ type: "CHECK_PINCODE_SUCCESS", data: response });
  } catch (e) {
    yield put({ type: "CHECK_PINCODE_SUCCESS", data: 'unavailable' });
  }
}

function* saga() {
    yield all([
        takeLatest(getCategories, fetchCategories),
        takeLatest(getSubCategories, fetchSubCategories),
        takeLatest(getProducts, fetchProducts),
        takeLatest(sendOTP, getOTP),
        takeLatest(verifyOTP, verifyUserOTP),
        takeLatest(getProductDetails, fetchProductDetails),
        takeLatest(addToCart, addItemToCart),
        takeLatest(getCartDetails, getCart),
        takeLatest(createCart, createNewCart),
        takeLatest(checkout, checkoutCart),
        takeLatest(getPaymentStatus, checkStatus),
        takeLatest(removeCartItem, removeItemInCart),
        takeLatest(getCatalogue, getCatalogueData),
        takeLatest(getAddresses, getAddress),
        takeLatest(createAddress, createNewAddress),
        takeLatest(updateAddress, updateAddressData),
        takeLatest(deleteAddress, deleteAddressData),   
        takeLatest(getOrders, getOrdersByPage),
        takeLatest(getUserDetails, getUserProfile),
        takeLatest(updateUserDetails, updateUserProfile),
        takeLatest(getWishlist, getWishlistItems),
        takeLatest(addToWishlist, addItemToWishlist),
        takeLatest(removeFromWishlist, removeItemFromWishlist),
        takeLatest(getRecommendedProducts, getAllRecommendedProducts),
        takeLatest(cancelOrder, cancelOrderById),
        takeLatest(subscribeEmail, subscribeEmailForNewsletter),
        takeLatest(checkPincode, checkPincodeForAvailability)
    ]);
}

export default saga;
