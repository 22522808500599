import { Input, Button, Form, Checkbox, ConfigProvider } from "antd";
import {
  createAddress, updateAddress, checkPincode,
  clearPincodeStatus
} from "../../store/actions";
import { useDispatch, connect, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { PINCODE_ERRORS } from "../../utils/enumData";
const { Search } = Input;

export default function AddressForm(props) {
  
  const BLOCK_CLASS = "address-box";
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  
  const pincodeCheckResponse = useSelector((state) => state.pincodeCheckResponse);
  const [pincodeStatus, setPincodeStatus] = useState("");
  const [pincodeErrorMessage, setPincodeErrorMsg] = useState("");
  const [inputPincodeVal, setInputPincodeVal] = useState("");

  useEffect(()=> {
    if (props?.data?.pincode !== undefined && props?.data?.pincode !== null) {
      setInputPincodeVal(props?.data?.pincode);
    }
  }, []);

  useEffect(() => {
    if (pincodeCheckResponse !== '') {
      if (pincodeCheckResponse !== '' && pincodeCheckResponse.etd !== undefined) {
        setPincodeStatus('verified');
      } else if (pincodeCheckResponse == 'unavailable') {
        setPincodeStatus(pincodeCheckResponse.trim());
        setPincodeErrorMsg(PINCODE_ERRORS.out_of_servicearea)
      } 
    }
  }, [pincodeCheckResponse]);

  const shouldUpdateAddress = () => {
    return props.data !== null;
  }

  const onFormSubmit = (e) => {
    if (pincodeStatus !== "verified" || pincodeStatus == '') return; 

    if (shouldUpdateAddress()) {
      let updatedAddress = { ...props.data, ...e, pincode: inputPincodeVal };
      dispatch(updateAddress(updatedAddress));
      dispatch(checkPincode(inputPincodeVal));
    } else {
      let formData = { ...e, pincode: inputPincodeVal };

      formData.label = e.full_name;
      dispatch(createAddress(formData));
    }
  }

  const onCancel = () => {
    props.clear();
    props.onDisplayModeChange();
  } 

  const getDefaultPhoneNo = () => {
    if (props?.data?.mobile !== undefined && props?.data?.mobile !== null) {
      return props.data.mobile;
    } else {
      return (userDetails?.mobile !== undefined||userDetails?.mobile !== null) ? userDetails.mobile : '';
    }
  }

  const onSearch = (value, _e) => {
    const pincodeRegex = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);
    
    if (pincodeRegex.test(value) == true) {
      dispatch(checkPincode(value));
      setPincodeErrorMsg('');
    } else {
      setPincodeStatus('unavailable');
      setPincodeErrorMsg(PINCODE_ERRORS.invalid);
    }
  };

  const updatePincode = (e) => {
    setInputPincodeVal(e.target.value);
    setPincodeStatus('');
  }

  return (
    <div className={`${BLOCK_CLASS}__form`}>
      <p className={`${BLOCK_CLASS}__sub-title`}>
        {props.data !== null ? "Update" : "Add new"} address :
      </p>
      <Form name="address" autoComplete="off" onFinish={onFormSubmit}>
        <Form.Item
          name="full_name"
          rules={[
            {
              required: true,
              message: "Please input Recipent Name!",
            },
          ]}
          initialValue={props?.data?.full_name}
        >
          <Input placeholder="Name" maxLength={30}/>
        </Form.Item>

        <Form.Item
          name="mobile"
          rules={[
            {
              required: true,
              message: "Please input Mobile no.!",
            },
          ]}
          initialValue={getDefaultPhoneNo()}
        >
          <Input placeholder="Mobile No."/>
        </Form.Item>

        <Form.Item
          name="flat_no"
          rules={[
            {
              required: true,
              message: "Please input Flat no.!",
            },
          ]}
          initialValue={props?.data?.flat_no}
        >
          <Input placeholder="Flat no." maxLength={20}/>
        </Form.Item>

        <Form.Item
          name="building_name"
          rules={[
            {
              required: true,
              message: "Please input Building name.!",
            },
          ]}
          initialValue={props?.data?.building_name}
        >
          <Input placeholder="Building name" maxLength={20}/>
        </Form.Item>

        <Form.Item
          name="area"
          rules={[
            {
              required: true,
              message: "Please input Street name!",
            },
          ]}
          initialValue={props?.data?.area}
        >
          <Input placeholder="Street name" maxLength={20}/>
        </Form.Item>

        <Form.Item
          name="city"
          rules={[
            {
              required: true,
              message: "Please input City!",
            },
          ]}
          initialValue={props?.data?.city}
        >
          <Input placeholder="City" maxLength={20}/>
        </Form.Item>

        <Form.Item
          name="state"
          rules={[
            {
              required: true,
              message: "Please input State!",
            },
          ]}
          initialValue={props?.data?.state}
        >
          <Input placeholder="State" maxLength={20}/>
        </Form.Item>

        <Form.Item
          name="pincode"
          rules={[
            {
              required: false,
              message: "Please input Pincode!",
            },
          ]}
          initialValue={inputPincodeVal}
        >
          <ConfigProvider
            theme={{
              token: {
                colorBorder: pincodeStatus == "verified" ? "#44983D" : "",
              },
            }}
          >
            <Search
              placeholder="Pincode"
              enterButton={pincodeStatus == "verified" ? "Verified" : "Verify"}
              onSearch={onSearch}
              status={pincodeStatus == "unavailable" ? "error" : ""}
              onChange={updatePincode}
              className={
                pincodeStatus == "verified"
                  ? `${BLOCK_CLASS}__pincode-btn--verified`
                  : `${BLOCK_CLASS}__pincode-btn`
              }
              // loading={pincodeStatus == "loading" ? true : false}
              value={inputPincodeVal}
            />
            {pincodeStatus.trim() == "unavailable" ? (
              <span className={`${BLOCK_CLASS}__error-msg`}>
                {pincodeErrorMessage}
              </span>
            ) : (
              ""
            )}
          </ConfigProvider>
        </Form.Item>

        <Form.Item
          name="landmark"
          rules={[
            {
              required: true,
              message: "Please input Landmark!",
            },
          ]}
          initialValue={props?.data?.landmark}
        >
          <Input placeholder="Landmark" maxLength={30}/>
        </Form.Item>

        <Form.Item
          name="primary"
          initialValue={props?.data?.primary}
          valuePropName="checked"
        >
          <Checkbox>Set as primary address?</Checkbox>
        </Form.Item>

        <div className={`${BLOCK_CLASS}__cta-wrapper`}>
          <Button
            className={`${BLOCK_CLASS}__secondary-cta`}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className={`${BLOCK_CLASS}__primary-cta`}
            htmlType="submit"
            disabled={pincodeStatus == "verified" ? false : true}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}
