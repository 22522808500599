import axios from 'axios';
import { logout } from "../store/actions";
import store from "../store/store";
import { useDispatch } from "react-redux";

const axiosInstance = axios.create({});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // const public_token = "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiZThiMGE3NWQtYzBjYy00OTY4LTk1YmQtNjRiNWYzNmFjMGNiIn0.msz5_bc4tvtmuGFGGXkf2LLe_z3TjhIKXaitVMHLCf0"
    const prod_public_token = "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiOWM4NWJhY2ItYzg4OS00Yzc0LTg4N2YtNjIzN2RlZDNmZmU0In0.G0nbp2WJEhB00PtoN3BIzOC2_JK2UzjOrWhw2TULrs0"
    // const public_token =
    //   "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMGY5ZDc5MzItYWIwNS00OGMzLWFiMWQtOTRlNjNkYjhlODMxIn0.xO6wBm99xkgYAFqYA6YpBWqMMFuvuSFRe38Qovw0A_Q";
    const apitoken = window.localStorage.getItem('token');
    config.headers["Authorization"] = `Bearer ${apitoken !== undefined && apitoken !== null ? apitoken : prod_public_token}`;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    console.error('Response error:', error.response.status);
    if ((error.response.status == 401) && (window.localStorage.getItem('token') !== undefined)) {
      store.store.dispatch(logout());
      window.localStorage.removeItem('token');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
