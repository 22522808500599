  import React, { useState, useEffect, useRef } from "react";
  import ImageWrapper from "../../components/Image/ImageWrapper";
  import {
    Button,
    Radio,
    Input,
    Space,
    Collapse,
    Carousel,
    Skeleton,
  } from "antd";
  import Recommendations from "../../components/Recommendations/Recommendations";
  import {
    addToCart,
    getProductDetails,
    getProductDetailsInProgress,
    togglePlaceOrderModal,
    addToWishlist,
    removeFromWishlist,
    getRecommendedProducts,
    showAppLoader,
    hideAppLoader,
    hideDrawer,
    checkPincode
  } from "../../store/actions";
  import { useDispatch, connect } from "react-redux";
  import { useLocation, useParams } from "react-router-dom";
  import { LOADER_TIMEOUT, DRAWER_TYPES } from "../../utils/enumData";

  const BLOCK_CLASS = "product";

  const Product = (props) => {
    const specRef = useRef();
    const { sku } = useParams();
    let skuId = sku.slice(sku.lastIndexOf('-') + 1, sku.length);
    const dispatch = useDispatch();
    const location = useLocation();
    const data = location.state;
    const [productSize, setProductSize] = useState(null);
    const [productQuantity, setProductQuantity] = useState(1);
    const [primaryImage, setPrimaryImage] = useState("");
    const [pincode, setPincode] = useState("");
    const [pincodeStatus, setPincodeStatus] = useState("");
    const [pincodeInputPlaceholder, setPincodeInputPlaceholder] = useState("Enter Your Pincode");
    const [isPincodeAvailableForService, setPincodeAvailability] = useState(null);

    const items = [
      {
        key: "1",
        label: "Description",
        children: <p>{props?.productDetails?.description}</p>,
        showArrow: true,
      },
      {
        key: "2",
        label: "Specs",
        children: <div dangerouslySetInnerHTML={{ __html: props?.productDetails?.specs }}></div>,
        showArrow: true,
      },
      {
        key: "3",
        label: "Care",
        children: <p>{props?.productDetails?.care_details}</p>,
        showArrow: true,
      },
    ];

    useEffect(() => {
      const timer = setTimeout(() => {
        dispatch(hideAppLoader());
      }, LOADER_TIMEOUT);
      return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
      dispatch(showAppLoader());
      onLoad();
      sendPageViewEvent()
    }, []);

    useEffect(() => {
      onLoad();
    }, [sku]);

    useEffect(() => {
      if (props.productDetails) {
        setPrimaryImage(props?.productDetails?.primary_image);
      }
    }, [props.productDetails]);

    useEffect(() => {
      if (props?.variantDetails?.properties?.size) {
        setProductSize(props?.variantDetails?.properties?.size);
      }
    }, [props.variantDetails]);

    useEffect(() => {
      setPincodeAvailability(props.pincodeCheckResponse);
    }, [props.pincodeCheckResponse]);

    const sendPageViewEvent = () => {
      let variantSku = getVariantSku();
      window.fbq('track', 'ViewContent', {
        content_ids: [variantSku], // 'REQUIRED': array of product IDs
        content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
      });
    }

    const onLoad = () => {
      skuId = sku.slice(sku.lastIndexOf('-') + 1, sku.length);
      window.scrollTo(0, 0);
      dispatch(getProductDetailsInProgress());
      dispatch(getProductDetails({ sku: skuId }));
      let randomPageNo = Math.floor(Math.random() * (10 - 1) + 1);
      dispatch(getRecommendedProducts(randomPageNo > 8 ? (Math.floor(Math.random() * (10 - 1) + 1)) : randomPageNo));

      if(props.isCartVisible) {
        dispatch(hideDrawer({type: DRAWER_TYPES.cart}));
      }
    }

    const getVariantSku = (size) => {
      let variantSize = size ? size : productSize;
      let combinations =
        props?.product?.productDetails?.variant_property_combinations;

      if (combinations !== undefined && combinations !== null) {
        let variantSku = '';
        combinations.forEach((dataObj) => {
          if (dataObj.properties.size == undefined || dataObj.properties.size == variantSize) {
            variantSku = dataObj.sku; 
          }
        });

        return variantSku;
      }
    };

    const showSizeChart = () => {
      dispatch(togglePlaceOrderModal({ type: 'sizechart' }));
    }

    const addItemToCart = () => {
      let variantSku = getVariantSku();
  
      let data = {
        isUserLoggedIn: props.isUserLoggedIn,
        variant_id: variantSku,
        name: props.productDetails.name,
        quantity: productQuantity,
        size: productSize,
        total_price: props.variantDetails.discounted_price,
        discount: props.variantDetails.discount_amount,
        unit_price: props.variantDetails.price,
        discount_percent: props.variantDetails.discount_percent,
        thumbnail: props.productDetails.primary_image,
        inventoryQuantity: props.variantDetails.quantity,
        variant_quantity: props.variantDetails.quantity,
        variant_properties: props.productDetails.variant_property_combinations
      };
      dispatch(addToCart({ data: data }));
      window.fbq('track', 'AddToCart', {
        content_ids: [variantSku], // 'REQUIRED': array of product IDs
        content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
      });
    };

    const onChange = (e) => {
      const variantPropertiesCombination = props.productDetails?.variant_property_combinations;

      if (variantPropertiesCombination && variantPropertiesCombination?.length > 0) {
        let currentSku = getVariantSku(e.target.value);
        dispatch(getProductDetails({ sku: currentSku }));
        setProductQuantity(1);
      }
      setProductSize(e.target.value);
    };

    const updateQuantity = (e) => {
      if (e === "increment") {
        if (productQuantity < props?.variantDetails?.quantity) {
          setProductQuantity(productQuantity + 1);
        }
      } else {
        if (productQuantity - 1 !== 0) {
          setProductQuantity(productQuantity - 1);
        }
      }
    };

    const isInWishlist = () => {
      let currentDisplayedProduct = props?.wishlist?.items?.filter((item) => {
        if (item.variant_sku == skuId) {
          return item;
        }
      });

      return currentDisplayedProduct?.length > 0 ? true : false;
    }

    const onWishlistClick = () => {
      if (!props.isUserLoggedIn) {
        dispatch(togglePlaceOrderModal({ type: "login" }));
        return
      }

      if (isInWishlist()) {
        dispatch(removeFromWishlist(skuId));
      } else {
        dispatch(addToWishlist(skuId));
      }
    }

    const checkInputPincode = () => {
      const pincodeRegex = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);

      if (pincodeRegex.test(pincode) == true) {
        setPincodeInputPlaceholder("");
        setPincodeStatus('');
        dispatch(checkPincode(pincode));
      } else {
        setPincode("");
        setPincodeInputPlaceholder("Please enter correct pincode");
        setPincodeStatus('error');
      }
    }

    const getEstimatedDeliveryDate = () => {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const superscriptsNumber = [
        "st","nd","rd","th","th","th","th","th","th","th",
        "th","th","th","th","th","th","th","th","th","th",
        "st","nd","rd","th","th","th","th","th","th","th",
        "st"
      ];
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 6);
      let month = months[currentDate.getMonth()];
      
      return <span>{`${currentDate.getDate()}`}<sup>{`${superscriptsNumber[currentDate.getDate() - 1]}`}</sup> {month}</span>;
    }

    const getProductUrl = (data) => {
      let artName = data?.properties?.art?.replaceAll(" ", "-");
      let productName = props?.productDetails?.name?.replaceAll(" ", "-")?.replaceAll('/','-');
      let sku = data.sku;
      
      return `/product/${artName}-${productName}-${sku}`;
    }

    const renderPrice = () => {
      return (
        <>
          {props.variantDetails?.discount_amount > 0 ? (
            <p className="price">
              <span>Rs. {props?.variantDetails?.discounted_price}</span>
              <span className="dashed">Rs. {props?.variantDetails?.price}</span>
              <span className="badge">Save Rs. {props.variantDetails?.discount_amount}</span>
            </p>
          ) : (
            <p className={`${BLOCK_CLASS}__price`}>
              <span>Rs. {props?.variantDetails?.price}</span>
            </p>
          )}
        </>
      );
    }

    return (
      <>
        <ImageWrapper
          name={`${window.innerWidth < 1024 ? "" : "banner-btm-desk"}`}
          alt="Clothing-img4"
          classes={`bg-element ${window.innerWidth < 1024 ? "hidden" : ""}`}
        />
        <div className={`${BLOCK_CLASS}`}>
          <div className={`${BLOCK_CLASS}__thumbnails-container`}>
            {props.isLoading ? (
              <>
                <Skeleton.Image
                  className="thumbnail-skeleton"
                  active={props.isLoading}
                />
              </>
            ) : (
              <>
                {props?.productDetails?.images.map((image) => {
                  return (
                    <ImageWrapper
                      externalUrl={image.url}
                      key={image.url}
                      classes={`${BLOCK_CLASS}__thumbnail-image`}
                      onclick={() => {
                        setPrimaryImage(image.url);
                      }}
                    />
                  );
                })}
              </>
            )}
          </div>

          <div className={`${BLOCK_CLASS}__main-image-wrapper`}>
            {props.isLoading && window.innerWidth > 1024 ? (
              <Skeleton.Image
                className="main-image-skeleton"
                active={props.isLoading}
              />
            ) : (
              <>
                {props?.productDetails?.primary_image &&
                window.innerWidth > 1024 ? (
                  <ImageWrapper
                    key={primaryImage}
                    externalUrl={primaryImage}
                    classes={`${BLOCK_CLASS}__main-image`}
                  />
                ) : (
                  ""
                )}
              </>
            )}

            {props.isLoading && window.innerWidth < 1024 ? (
              <>
                <Skeleton.Image
                  className="main-image-skeleton"
                  active={props.isLoading}
                />
              </>
            ) : (
              <>
                {!props.isLoading &&
                props?.productDetails?.primary_image &&
                window.innerWidth < 1024 ? (
                  <Carousel>
                    {props?.productDetails?.images.map((image) => {
                      return (
                        <ImageWrapper
                          externalUrl={image.url}
                          classes={`${BLOCK_CLASS}__main-image`}
                        />
                      );
                    })}
                  </Carousel>
                ) : null}
              </>
            )}
          </div>

          <div className={`${BLOCK_CLASS}__content-wrapper`}>
            {props.isLoading ? (
              <>
                <Skeleton
                  className="content-skeleton"
                  active={props.isLoading}
                />
              </>
            ) : (
              <>
                <div
                  className={`${BLOCK_CLASS}__name-price-wrapper ${
                    props.variantDetails?.discount_amount == 0
                      ? `${BLOCK_CLASS}__name-price-wrapper--no-discount`
                      : ""
                  }`}
                >
                  <h1 className="name">{props?.productDetails?.name}</h1>
                  {renderPrice(props?.productDetails)}
                </div>

                <div className={`${BLOCK_CLASS}__variant-wrapper`}>
                  {props?.allProperties !== undefined &&
                  props?.allProperties !== null ? (
                    <Radio.Group
                      onChange={onChange}
                      defaultValue={props?.variantDetails?.properties?.size}
                      buttonStyle="solid"
                    >
                      {props.allProperties?.map((variant) => {
                        if (
                          variant?.properties?.size &&
                          variant?.properties?.size !== "free size"
                        ) {
                          return (
                            <Radio.Button
                              value={variant?.properties?.size}
                              key={variant?.properties?.size}
                              disabled={variant.quantity < 1}
                            >
                              {variant.properties.size}
                            </Radio.Button>
                          );
                        } else if (variant?.properties?.size == "free size") {
                          return (
                            <h3 className={`${BLOCK_CLASS}__size-label`}>
                              Size :
                              <span className={`${BLOCK_CLASS}__size-chip`}>
                                Free Size
                              </span>
                            </h3>
                          );
                        }
                      })}
                    </Radio.Group>
                  ) : (
                    <></>
                  )}

                  <div className="hidden">
                    {props.allProperties?.map((variant) => {
                      return <a href={getProductUrl(variant)}></a>;
                    })}
                  </div>

                  {props?.allProperties?.length > 0 &&
                  props?.allProperties[0].properties?.size ? (
                    <div className={`${BLOCK_CLASS}__size-guide-wrapper`}>
                      <p className="guide-cta" onClick={showSizeChart}>
                        Size guide
                      </p>
                      <ImageWrapper
                        name="ruler"
                        alt=""
                        classes={`guide-icon`}
                        onClick={showSizeChart}
                      />
                    </div>
                  ) : null}
                </div>

                <div className={`${BLOCK_CLASS}__quantity-wishlist-wrapper`}>
                  <div className={`${BLOCK_CLASS}__quantity-wrapper`}>
                    <Button
                      onClick={() => {
                        updateQuantity("decrement");
                      }}
                    >
                      -
                    </Button>{" "}
                    {productQuantity}{" "}
                    <Button
                      disabled={
                        productQuantity == props?.productDetails?.quantity
                      }
                      onClick={() => {
                        updateQuantity("increment");
                      }}
                    >
                      +
                    </Button>
                  </div>

                  <div className={`${BLOCK_CLASS}__wishlist-icon-wrapper`}>
                    <ImageWrapper
                      onclick={onWishlistClick}
                      name={isInWishlist() ? "in-wishlist" : "wishlist"}
                      alt="Wishlist button"
                      classes={`${BLOCK_CLASS}__wishlist`}
                    />
                  </div>
                </div>

                <Button
                  className={`${BLOCK_CLASS}__buy-now-cta`}
                  onClick={addItemToCart}
                  disabled={props?.variantDetails?.quantity == 0}
                >
                  {props?.variantDetails?.quantity == 0
                    ? "Out of stock"
                    : "Add to cart"}
                </Button>

                <Space.Compact
                  className={`${BLOCK_CLASS}__pincode-input-wrapper`}
                >
                  <Input
                    status={pincodeStatus}
                    placeholder={pincodeInputPlaceholder}
                    onChange={(e) => {
                      setPincodeAvailability(null);
                      setPincode(e.target.value);
                    }}
                    value={pincode}
                  />
                  <Button type="primary" onClick={checkInputPincode}>
                    Check
                  </Button>
                </Space.Compact>

                {isPincodeAvailableForService !== null && isPincodeAvailableForService.etd !== undefined ? (
                  <div>
                    <p className={`${BLOCK_CLASS}__delivery-note`}>
                      <ImageWrapper name="delivery-icon"></ImageWrapper>
                      Free delivery available
                    </p>

                    <p className={`${BLOCK_CLASS}__delivery-note`}>
                      <ImageWrapper name="delivery-date-icon"></ImageWrapper>
                      <span>
                        Expect delivery by {isPincodeAvailableForService?.etd}
                      </span>
                    </p>

                    <p className={`${BLOCK_CLASS}__delivery-note`}>
                      <ImageWrapper name="exchange-icon"></ImageWrapper>
                      Size exchanges accepted within 3 days of delivery.
                    </p>
                  </div>
                ) : null}

                {isPincodeAvailableForService == "unavailable" ? (
                  <div>
                    <p
                      className={`${BLOCK_CLASS}__delivery-note ${BLOCK_CLASS}__delivery-note-error`}
                    >
                      <ImageWrapper name="delivery-icon"></ImageWrapper>
                      Apologies, your location is currently outside our delivery
                      zone.
                    </p>
                  </div>
                ) : null}

                <Collapse
                  items={items}
                  defaultActiveKey={["1"]}
                  className={`${BLOCK_CLASS}__description-wrapper`}
                  expandIconPosition="end"
                />
                <div className={`${BLOCK_CLASS}__feature-info`}>
                  <p className={`${BLOCK_CLASS}__feature-info__sub-head`}>
                    By choosing us, you support our genuine passion.
                  </p>

                  <div className={`${BLOCK_CLASS}__icons-wrapper`}>
                    <div className="col">
                      <ImageWrapper
                        name="handcrafted-feature"
                        alt="Handcrafted"
                        classes={`icon`}
                      />
                      <br></br>
                      <span>Handcrafted</span>
                    </div>
                    <div className="col">
                      <ImageWrapper
                        name="timelesss-artistry"
                        alt="Timeless Artistry"
                        classes={`icon`}
                      />
                      <br></br>
                      <span>Timeless Artistry</span>
                    </div>
                    <div className="col">
                      <ImageWrapper
                        name="uplifting-artisans"
                        alt="Uplifting Artisans"
                        classes={`icon`}
                      />
                      <br></br>
                      <span>Uplifting Artisans</span>
                    </div>
                    <div className="col">
                      <ImageWrapper
                        name="mindful-production"
                        alt="mindful production"
                        classes={`icon`}
                      />
                      <br></br>
                      <span>Mindful Production</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Recommendations />
      </>
    );
  };

  function mapStateToProps(state, ownProps) {
    const { wishlist, product, isUserLoggedIn, isLoading, isCartVisible, pincodeCheckResponse } = state;
    return {
      isCartVisible,
      product: product,
      productDetails: product ? product.productDetails : null,
      variantDetails: product ? product.variantDetails : null,
      allProperties: product ? product.productDetails.variant_property_combinations : null,
      isUserLoggedIn: isUserLoggedIn,
      isLoading: isLoading,
      wishlist: wishlist,
      pincodeCheckResponse: pincodeCheckResponse 
    };
  }

  export default connect(mapStateToProps)(Product);
