import React, { useState, useEffect, useRef } from "react";
import ImageWrapper from "../../components/Image/ImageWrapper";
import { subscribeEmail } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
const NEWSLETTER_CLASS = "newsletter";

const Newsletter = () => {
  const dispatch = useDispatch();
  const isSubscriptionSuccessful = useSelector((state) => state.isSubscriptionSuccessful);
  const [email, setEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState("");
  const [emailPlaceholder, setEmailPlaceholder] = useState("Enter your email here");
  const [isFormVisible, setIsFormVisible] = useState(true);

  useEffect(() => {
    if (isSubscriptionSuccessful) {
      setIsFormVisible(false);
    }
  }, [isSubscriptionSuccessful])

  const checkEmail = () => {
    const emailRegex = new RegExp(/^\S+@\S+\.\S+$/);

    if (emailRegex.test(email) == true) {
      setEmailPlaceholder("");
      setEmailStatus("");
      dispatch(subscribeEmail({
        email: email,
        newsletter_opt_in: true,
        source: "website"
      }));
    } else {
      setEmail("");
      setEmailPlaceholder("Please enter correct email");
      setEmailStatus("error");
    }
  };

    return (
      <section className="newsletter-main">
        <div className="container">
          <div className={`${NEWSLETTER_CLASS}`}>
            <div className={`${NEWSLETTER_CLASS}__title`}>
              <h3>Stay Connected</h3>
            </div>
            <div className={`${NEWSLETTER_CLASS}__inner-sec`}>
              <div className={`${NEWSLETTER_CLASS}__info`}>
                <p>
                  Sign up for Nilam India's newsletter for exclusive updates on our latest
                  products and designs.
                </p>
              </div>
              {isFormVisible ? (
                <div className={`${NEWSLETTER_CLASS}__formsection`}>
                  <form className={`${NEWSLETTER_CLASS}__form`}>
                    <input
                      type="email"
                      className={`${NEWSLETTER_CLASS}__emailinput ${
                        emailStatus == "error"
                          ? `${NEWSLETTER_CLASS}__emailinput-error`
                          : ""
                      }`}
                      placeholder={emailPlaceholder}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    ></input>
                    <button
                      type="button"
                      className={`${NEWSLETTER_CLASS}__signup`}
                      onClick={checkEmail}
                    >
                      Sign up
                    </button>
                  </form>
                </div>
              ) : (
                <p className={`${NEWSLETTER_CLASS}__success-note`}>
                  <ImageWrapper name="confirm-icon"></ImageWrapper>
                  You have successfully subscribed to Nilam India's newsletter.
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    );
};

export default Newsletter;