import React, { useState, useEffect } from "react";
import AddressCard from "./AddressCard";
import AddressForm from "./AddressForm";
import { useDispatch, connect } from "react-redux";
import {
  deleteAddress,
  setAddressFormVisibility,
  toggleMobileDrawerMenu,
  checkPincode,
  clearPincodeStatus
} from "../../store/actions";
import ImageWrapper from "../Image/ImageWrapper";

const Address = (props) => {
  const BLOCK_CLASS = "address-box";

  const dispatch = useDispatch();
  // Address which is in edit mode = selectedAddress
  const [selectedAddress, selectAddress] = useState(null);
  const [currentAddress, selectCurrentAddress] = useState(null);

  useEffect(()=> {
    window.scrollTo(0,0);
    if (props.isMobileMenuOpen) {
      dispatch(toggleMobileDrawerMenu());
    }
  }, []);

  useEffect(()=> {
    if (props.defaultAddressId !== undefined && props.defaultAddressId !== null) {
      selectCurrentAddress(props.defaultAddressId);
      dispatch(checkPincode(props.defaultAddressPincode));
    }
  }, [props.defaultAddressId]);

  const onDisplayModeChange = () => {
    if (props.onEditAddressClick) {
      props?.onEditAddressClick(props.isAddressFormVisible);
    }
    dispatch(setAddressFormVisibility());
  }

  const onEditAddress = (address) => {
    selectAddress(address);
    onDisplayModeChange();
  } 

  const clearSelectedAddress = () => {
    selectAddress(null)
  }

  useEffect(()=> {
    if (props.isAddressFormVisible === false) {
      clearSelectedAddress();
    }
  }, [props.isAddressFormVisible])

  const onDelete = (id) => {
    dispatch(deleteAddress(id));

    if (props.addresses.length == 0) {
      dispatch(clearPincodeStatus());
    }
  }

  const onSelectCurrentAddress = (id) => {
    selectCurrentAddress(id);
    if (props?.onAddressSelection) {
      props?.onAddressSelection(id);
    }
  }

  return (
    <>
      {!props.isAddressFormVisible ? (
        <p className={`${BLOCK_CLASS}__title`}>
          {window.location.href.includes('address') ? 'Manage' : 'Select'} Address <span onClick={onDisplayModeChange}>&#43; Add</span>
        </p>
      ) : null}

      {props.isAddressFormVisible ? (
        <AddressForm onDisplayModeChange={onDisplayModeChange} data={selectedAddress} clear={clearSelectedAddress}/>
      ) : (
        <div className={`${BLOCK_CLASS}__list-wrapper ${props.addresses == 0 ? `${BLOCK_CLASS}__list-wrapper--empty` : ''}`}>
          {
            props.addresses.map((data)=> {
              return (
                <AddressCard
                  key={data.id}
                  data={data}
                  currentAddress={currentAddress}
                  onEdit={onEditAddress}
                  onDelete={onDelete}
                  onSelect={onSelectCurrentAddress}
                />
              );
            })
          }

          {
            props.addresses.length == 0 ? (
              <ImageWrapper
                  name="no-address"
                  alt="No address to show"
                  classes={`${BLOCK_CLASS}__no-items`}
                ></ImageWrapper>
            ) : null
          }
        </div>
      )}

      {/* {!props.isAddressFormVisible ? (
        <Button
          className={`${BLOCK_CLASS}__primary-cta`}
          onClick={onPlaceOrder}
        >
          Place Order
        </Button>
      ) : null} */}
    </>
  );
};

function mapStateToProps(state, ownProps) {
  const { cartDetails, isAddressFormVisible, addresses, isMobileMenuOpen, pincodeCheckResponse } = state;
  return {
    cartDetails, isAddressFormVisible, addresses, isMobileMenuOpen, pincodeCheckResponse
  };
}

export default connect(mapStateToProps)(Address);