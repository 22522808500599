import React, { useEffect, useState } from "react";
import { memo } from 'react';
import ImageWrapper from "../../components/Image/ImageWrapper";
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { getSubCategories, toggleMobileDrawerMenu, showAppLoader, hideAppLoader } from "../../store/actions";
import Quotes from '../../quotes.json';
import { LOADER_TIMEOUT } from "../../utils/enumData";

const Subcategory = memo((props) => {
	const { category_name, subcategory_name } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showAppLoader());
    onLoad();
    const timer = setTimeout(() => {
      dispatch(hideAppLoader());
    }, LOADER_TIMEOUT);
    return () => clearTimeout(timer);
  }, []);

  useEffect(()=> {
    onLoad();
  }, [subcategory_name]);

  useEffect(() => {
    let params = `${category_name}${subcategory_name !== undefined ? `|${subcategory_name}` : ''}`
    dispatch(getSubCategories(params));
    if (props.isMobileMenuOpen) {
      dispatch(toggleMobileDrawerMenu());
    }
	}, [category_name, subcategory_name]);

  const onLoad = () => {
    window.scrollTo(0, 0);
    if (props.isMobileMenuOpen) {
      dispatch(toggleMobileDrawerMenu());
    }
  }

  const getLinkUrl = (item) => {
    if (item.has_subcategories) {
      return `/collection/${category_name}/${item.name}`;
    } else {
      return `/products/${category_name}/${item.name}/${item.id}`;
    }
  }

  return (
    <div className="sub-category">
      <section className="hero-banner"></section>
      <div className="container">
        <div className="sub-category-card">
          <div className="sub-category-title">
            <h3>{subcategory_name ? subcategory_name : category_name}</h3>
          </div>
          <div className="sub-category-list">
            {props.subcategories?.map((item) => {
              return (
                <figure className="sub-category-item">
                  <Link to={getLinkUrl(item)} state={item}>
                    {item?.images[0]?.url !== undefined ? (
                      <ImageWrapper
                        key={item?.images[0]?.url}
                        externalUrl={item?.images[0]?.url}
                        alt={item.name}
                      />
                    ) : (
                      <ImageWrapper
                        name={"Women"}
                        alt={item.name}
                        srcType="internal"
                      />
                    )}
                  </Link>
                  <Link to={getLinkUrl(item)} state={item}>
                    <figcaption>{item.name}</figcaption>
                  </Link>
                  <Link to={getLinkUrl(item)} state={item}>
                    <div className="sub-category-item__card-cta">
                      <p>Quick view</p>
                    </div>
                  </Link>
                </figure>
              );
            })}
          </div>
          {props.categories?.length > 1 ? (
            <div className="other-category">
              <div className="other-category-title">
                <h4>Other Categories</h4>
              </div>
              <div className="other-category-card">
                {props?.categories?.map((category) => {
                  if (category.name !== category_name) {
                    let url = (category == "Clothing") ? `collection/Clothing` : `/products/Bags/${props?.catalogue?.categories?.Bags?.id}`;
                    return (
                      <Link to={url}>
                        <figure className="other-list">
                          <ImageWrapper
                            name={"accessorie-category"}
                            alt={category.name}
                            srcType="internal"
                          />
                          <figcaption>{category.name}</figcaption>
                        </figure>
                      </Link>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            ''
          )}
          <ImageWrapper name={`${window.innerWidth < 1024 ? 'mobile-separator' : 'separator-bg'}`} alt="separator" classes={`design-separator`}/>
          <div className="category-content">
            <p>{ Quotes.quotes[(Math.floor(Math.random() * (38 - 1) + 1))] }</p>
          </div>
        </div>
      </div>
    </div>
  );
});

function mapStateToProps(state, ownProps) {
  const { subcategories, mappedCategories, categories, isMobileMenuOpen, catalogue } = state;
  return { subcategories: subcategories, mappedCategories, categories, isMobileMenuOpen, catalogue }
}

export default connect(mapStateToProps)(Subcategory)
